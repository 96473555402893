export default {
  activeMode: 'active-mode',
  activeClass: "active",
  windowEl: window,
  documentEl: document,
  htmlEl: document.documentElement,
  bodyEl: document.body,
  header: document.querySelector("header"),
  burger: document.querySelectorAll(".burger"),
  mobileMenu: document.querySelector(".mobile"),
  overlay: document.querySelector("[data-overlay]"),
}





